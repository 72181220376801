import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Logos from "../components/logos";

export default function Template({
    data,
}) {
    const { site, markdownRemark } = data;
    const { siteMetadata } = site;
    const { frontmatter } = markdownRemark;
    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>


            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Our History</span>
                                        <span className=" block text-white">Understand</span>
                                        <span className=" block text-white">Our Journey</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white my-10 sm:my-0">
                <div class="relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div class="relative md:bg-white md:p-6">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                <p>Tim Benstead Associates Ltd is a newly formed consultancy firm focussed solely on the electrical installation sector. </p>
                                <p>Tim has spent many years within the electrical installation sector and has wide experience of British Standards and how they relate to electrical installation work. </p>
                            </div>
                            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                                <p>He is a member of BSI and serves on a number of British Standard committees. He is active on the committee dealing with fire detection and fire alarm systems (FSH12/1) and is Chair of JPEL64/B (Thermal effects) and JPEL, the main committee for the IET Wiring Regulations.</p>
                                <p>Tim spent many years as the Principal Engineer/Author with Certsure LLP and the brands NICEIC and ELECSA. He was responsible for all their technical publications and certificates. Tim is also a member of the CIBSE working group on electricity, and is currently helping to update CIBSE Guide K.</p>
                            </div>
                            {/* <div className="bg-white w-40 ">
                                <img className="object-cover" src="../assets/BSI-Membership-Badge.png" alt="BSI Membership Badge" />
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>

            <Logos />

            <div className="bg-indigo-400">
                <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-12">
                        <div class="text-base max-w-prose lg:max-w-none">
                            <h2 class="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Testimonials</h2>
                            <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">What our customers have said about us</p>
                        </div>
                        <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
                            {
                                frontmatter.testimonials.map(quote =>

                                    <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">

                                        <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                                            <div className="relative text-lg font-medium text-black md:flex-grow">
                                                <svg className="h-12 w-12 text-black opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                </svg>
                                                <p className="relative">
                                                    {quote.description}
                                                </p>
                                            </div>
                                            <footer className="mt-8">
                                                <div className="flex items-start">
                                                    <div className="ml-4">
                                                        <div className="text-base font-medium text-black">{quote.name}</div>
                                                        <div className="text-base font-medium text-black">{quote.position}</div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </blockquote>
                                    </div>

                                )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metaDescription
        intro
        testimonials {
            name
            position
            description
        }
      }
    }
  }
`;
